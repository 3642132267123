<script setup lang="ts">
interface Props {
    content: StoryblokRichTextInterface;
}
const props = defineProps<Props>();
const renderedRichText = computed(() => renderRichText(props.content));
</script>

<template>
    <!-- eslint-disable vue/no-v-html -->
    <div
        class="storyblok-rich-text"
        v-html="renderedRichText" />
</template>

<style lang="scss" scoped>
.storyblok-rich-text {
    /* stylelint-disable selector-no-qualifying-type */
    /**
    * The following code is used to prevent content that is
    * pasted into the rich text editor from having a color
    * that is not our styled color. This is most commonly
    * seen when pasting from a Google Doc.
    *
    * The selector span[style*='color:'] will match any
    * Google Doc content but will not match content in Storyblok
    * that has been styled with a color; it uses hex values instead.
    */
    // TODO it seems like google docs no longer actually follow the rgb format
    :deep(p > span[style*='color:']) {
        color: inherit !important;
    }

    :deep(h1, h2, h3, h4, h5, h6) {
        > span[style*='color:'] {
            color: inherit !important;
        }
    }

    :deep(a > span[style*='color:']) {
        color: inherit !important;

        u {
            text-decoration: none !important;
        }
    }
    /* stylelint-disable-enable selector-no-qualifying-type */
}
</style>
